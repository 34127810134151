import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plb',
  templateUrl: './plb.component.html',
  styleUrls: ['./plb.component.css']
})

export class PlbComponent implements OnInit {
  showAddToCartForm: boolean = false;
  catalogNumber: string;

  constructor() { }

  ngOnInit() {
  }

  addToCart(event, catnum) {
    this.catalogNumber = catnum;
    this.showAddToCartForm = true; 
    console.log("'Add to Cart' button for " +  catnum + " clicked");
    //console.log("'Add to Cart' button clicked");

  }

  saveForm(event) {
    this.showAddToCartForm = false;
  }

  cancelForm(event) {
    this.showAddToCartForm = false;
  }

}