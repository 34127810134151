import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PlbComponent } from './components/plb/plb.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PlbTestComponent } from './components/plb-test/plb-test.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'plb', component: PlbComponent },
  { path: 'plbtest', component: PlbTestComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: false }  // <-- debugging purposes only
      )
  ],
  exports: [
    RouterModule
  ], 
  declarations: []
})
export class AppRoutingModule { }
