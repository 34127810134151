import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PlbComponent } from './components/plb/plb.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PlbTestComponent } from './components/plb-test/plb-test.component';
import { HttpTestComponent } from './components/http-test/http-test.component';

@NgModule({
  declarations: [
    AppComponent,
    PlbComponent,
    HomeComponent,
    PageNotFoundComponent,
    PlbTestComponent,
    HttpTestComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
